import React from "react";
import axios from "axios";
import { Formik, Form, FastField, ErrorMessage } from "formik";
import Recaptcha from "react-google-recaptcha";
import * as Yup from "yup";
import { url, key } from "data/config";
import { Button, Input, Textarea } from "components/common";
import { Center, InputField } from "./styles";

// const RECAPTCHA_KEY = `${process.env.GATSBY_SITE_RECAPTCHA_KEY}`

const encode = (data) => {
	return Object.keys(data)
		.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
		.join("&");
}

class ContactForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = { name: "", email: "", message: "" };
	}

	handleSubmit = e => {
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({ "form-name": "contact", ...this.state })
		})
			.then(() => {
				alert("Thanks for reaching out, I'll get back to you shortly!")
				this.setState({ name: "", email: "", message: "" })
			})
			.catch(error => alert(error));

		e.preventDefault();
	};

	handleChange = e => this.setState({ [e.target.name]: e.target.value });

	render() {
		const { name, email, message } = this.state;
		const style = {
			display: "none",
		};
		return (
			<form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={this.handleSubmit}>

				<p>
					<Input type="text" name="name" value={name} placeholder="Full Name" onChange={this.handleChange} />
				</p>
				<p style={style}>
					<label>Purpose: <input name="bot-field" /></label>
				</p>
				<p>
					<Input type="email" name="email" value={email} placeholder="Email" onChange={this.handleChange} />
				</p>
				<p>
					<InputField>
						<Textarea component="textarea" name="message" rows="8" type="text" placeholder="Message" value={message} onChange={this.handleChange} />
					</InputField>
				</p>
				<p>
					<Button secondary type="submit">Send</Button>
				</p>
			</form>
		);
	}
}

export default ContactForm;
