import React, { useContext } from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Card, TitleWrap, Button } from 'components/common';
// import Star from 'components/common/Icons/Star';
// import Fork from 'components/common/Icons/Fork';
import portrait from 'assets/illustrations/portrait.svg';
import event from 'assets/illustrations/events.svg';
import travel from 'assets/illustrations/travel.svg';
import aerial from 'assets/illustrations/drone.svg';
import urban from 'assets/illustrations/urban.svg';
import landscape from 'assets/illustrations/landscape.svg';
import { Wrapper, Grid, Item, Content, Stats, Languages } from './styles';
// import Travel from '../../../pages/travel'
import { Link } from 'gatsby'


export const Projects = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper as={Container} id="projects">
      <h2>Portfolio</h2>
      <Grid>
        <Item key='travel' as="a" theme={theme}>
          <Card theme={theme}>
            <Content>
              <h4>Travel</h4>
              <img height="200" width="200" src={travel} />
              <TitleWrap>
                <Link to="/travel">
                  <Button>Travel</Button>
                </Link>
              </TitleWrap>
            </Content>
          </Card>
        </Item>
        <Item key='portraits' as="a" theme={theme}>
          <Card theme={theme}>
            <Content>
              <h4>Portraits</h4>
              <img height="200" width="200" src={portrait} />
            </Content>
            <TitleWrap>
              <a href="/portraits">
                <Button>Portraits</Button>
              </a>
            </TitleWrap>
          </Card>
        </Item>

        <Item key='events' as="a" theme={theme}>
          <Card theme={theme}>
            <Content>
              <h4>Events</h4>
              <img height="200" width="200" src={event} />
            </Content>
            <TitleWrap>
              <a href="/events">
                <Button>Events</Button>
              </a>
            </TitleWrap>
          </Card>
        </Item>
        <Item key='Aerial' as="a" theme={theme}>
          <Card theme={theme}>
            <Content>
              <h4>Aerial</h4>
              <img height="200" width="200" src={aerial} />
            </Content>
            <TitleWrap>
              <a href="/aerial">
                <Button>Drone</Button>
              </a>
            </TitleWrap>
          </Card>
        </Item>
        <Item key='Urban' as="a" theme={theme}>
          <Card theme={theme}>
            <Content>
              <h4>Urban</h4>
              <img height="200" width="200" src={urban} />
            </Content>
            <TitleWrap>
              <a href="/urban">
                <Button>Urban</Button>
              </a>
            </TitleWrap>
          </Card>
        </Item>
        <Item key='Landscapes' as="a" theme={theme}>
          <Card theme={theme}>
            <Content>
              <h4>Landscapes</h4>
              <img height="200" width="200" src={landscape} />
            </Content>
            <TitleWrap>
              <a href="/landscapes">
                <Button>Landscapes</Button>
              </a>
            </TitleWrap>
          </Card>
        </Item>
      </Grid>
    </Wrapper>

  );
};
